.ng-trigger-collapsedCard {
  .card-body {
    padding-top: 0;
  }
}

.progressbar-stack .progress,
.progressbar-stack .progress-bar {
  margin: 0 1px;
  border-radius: 3px;
}

.btn-page .btn {
  margin-right: 8px;
}

.scrollable-body {
  padding: 0 !important;

  perfect-scrollbar {
    padding: 20px 25px;
  }
}

.pill-card .nav-pills {
  padding-bottom: 15px;
}

.user-profile-list {
  table.dataTable.display tbody td,
  table.dataTable.display tbody th,
  table.dataTable.row-border tbody td,
  table.dataTable.row-border tbody th {
    border-top: none;
  }
}

.label-control {
  display: inline-flex;
  align-items: center;

  input,
  select {
    margin: 0 5px;
  }
}

.dataTables_filter,
.dataTables_length {
  label {
    display: flex;
    align-items: center;
  }

  input,
  select {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.95rem;
    font-size: 0.875rem;
    margin: 0 5px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 2px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

.marge-card {
  box-shadow: $card-shadow;

  .card {
    box-shadow: none;
  }

  .index-minus {
    z-index: -1;
  }
}

.rating-bar .br-horizontal {
  display: block;
}

.gradientaccordion {
  .card-header {
    padding: 12px;

    button {
      margin: 0;
    }
  }
}

.tab-card {
  .nav-tabs {
    margin-bottom: 1rem;
  }

  .nav-pills {
    margin-bottom: 1rem;

    .nav-item {
      text-align: center;
    }
  }
}

.utility-card label {
  margin-right: 3px;
}

.users-contact {
  z-index: 1;
  position: relative;
}

.user-card .user-about-block .position-relative.d-inline-block {
  z-index: 15;
}

.user-profile .user-about-block .certificated-badge {
  z-index: 16;
}

.img-row {
  display: inline-block;
}

.img-frame {
  margin: 10px;
  border: 5px solid #fff;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.single-image-gallery {
  .col-auto {
    padding: 0;
  }
}

.custom-range-datepicker {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;

  &.focused {
    background-color: #e6e6e6;
  }

  &.range,
  &:hover {
    background-color: $primary-color;
    color: white;
  }

  &.faded {
    background-color: rgba(2, 117, 216, 0.5);
  }
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  border-radius: 0.25rem;
  display: inline-block;
  width: 2rem;

  &.focused,
  &:hover {
    background-color: #e6e6e6;
  }
}

.weekend {
  background-color: $warning-color;
  border-radius: 1rem;
  color: white;
}

.hidden {
  display: none;
}

select-dropdown .highlighted,
select-dropdown .selected.highlighted {
  background-color: $primary-color !important;
}

.table {
  td,
  th {
    font-family: $theme-font-family;
  }
}

.pcoded-header {
  .search-bar {
    display: block;
  }
}

.trnasiction-card .transection-preogress {
  margin-top: 5px;
}

.pcoded-navbar.theme-horizontal .pcoded-inner-navbar {
  > app-nav-group > app-nav-collapse > li.pcoded-hasmenu.pcoded-trigger:before,
  > app-nav-group > app-nav-collapse > li.pcoded-hasmenu.active:before,
  app-nav-item > li.pcoded-hasmenu.active:before,
  app-nav-item > li.pcoded-hasmenu.pcoded-trigger:before {
    bottom: -27px;
    text-shadow: 0 1px 2px rgba(54, 80, 138, 0.3);
  }
}

.h-list-body .chat-messages .chat-menu-reply > div p,
.pcoded-header.header-blue a.h-back-user-list {
  color: $theme-font-color;
}

.header-chat .h-list-footer .input-group .form-control {
  height: auto;
}
.pcoded-navbar.menu-light:not(.theme-horizontal){
  .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.active > a,
  .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li.pcoded-trigger > a,
  .pcoded-inner-navbar > app-nav-group > app-nav-collapse > li:hover >a,
  .pcoded-inner-navbar > app-nav-group > app-nav-item > li.active >a,
  .pcoded-inner-navbar > app-nav-group > app-nav-item > li.pcoded-trigger >a,
  .pcoded-inner-navbar > app-nav-group > app-nav-item > li:hover >a{
    background: rgba(202, 202, 202, 0.3);
    &:before{
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 100%;
      background: $primary-color;
    }
  }
}

.data-node-list {
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }

  .dataTables_processing {
    z-index: 100;
    height: 100px;

    .container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 15vh;
      overflow: hidden;
      animation-delay: 1s;

      .item-1 {
        width: 20px;
        height: 20px;
        background: #f583a1;
        border-radius: 50%;
        background-color: #eed968;
        margin: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50%,
        75% {
          transform: scale(2.5);
        }
        78%,
        100% {
          opacity: 0;
        }
      }
      .item-1:before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #eed968;
        opacity: 0.7;
        animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
        animation-delay: 200ms;
        transition: 0.5s all ease;
        transform: scale(1);
      }
      .item-2 {
        width: 20px;
        height: 20px;
        background: #f583a1;
        border-radius: 50%;
        background-color: #eece68;
        margin: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50%,
        75% {
          transform: scale(2.5);
        }
        78%,
        100% {
          opacity: 0;
        }
      }
      .item-2:before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #eece68;
        opacity: 0.7;
        animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
        animation-delay: 400ms;
        transition: 0.5s all ease;
        transform: scale(1);
      }

      .item-3 {
        width: 20px;
        height: 20px;
        background: #f583a1;
        border-radius: 50%;
        background-color: #eec368;
        margin: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50%,
        75% {
          transform: scale(2.5);
        }
        78%,
        100% {
          opacity: 0;
        }
      }
      .item-3:before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #eec368;
        opacity: 0.7;
        animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
        animation-delay: 600ms;
        transition: 0.5s all ease;
        transform: scale(1);
      }

      .item-4 {
        width: 20px;
        height: 20px;
        background: #f583a1;
        border-radius: 50%;
        background-color: #eead68;
        margin: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50%,
        75% {
          transform: scale(2.5);
        }
        78%,
        100% {
          opacity: 0;
        }
      }
      .item-4:before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #eead68;
        opacity: 0.7;
        animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
        animation-delay: 800ms;
        transition: 0.5s all ease;
        transform: scale(1);
      }

      .item-5 {
        width: 20px;
        height: 20px;
        background: #f583a1;
        border-radius: 50%;
        background-color: #ee8c68;
        margin: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50%,
        75% {
          transform: scale(2.5);
        }
        78%,
        100% {
          opacity: 0;
        }
      }
      .item-5:before {
        content: '';
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: #ee8c68;
        opacity: 0.7;
        animation: scale 2s infinite cubic-bezier(0, 0, 0.49, 1.02);
        animation-delay: 1000ms;
        transition: 0.5s all ease;
        transform: scale(1);
      }
    }
  }

  table {
    border-spacing: 0 10px;
    width: calc(100% - 10px);
    margin: 0 5px;

    tbody {
      tr {
        box-shadow: $card-shadow;
        border-radius: $border-radius;
        position: relative;
        height: auto;
        /*height: 45px;*/

        .hidden-label {
          display: none;
        }

        .overlay-actions {
          position: absolute;
          opacity: 1;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: left;
          padding-left: 10px;

          .btn {
            border-radius: 50%;
            margin: 0 3px;
            width: 35px;
            height: 35px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }

        .overlay-state {
          position: absolute;
          opacity: 0;
          top: 0;
          right: 0;
          background: #4099ff;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: left;
          padding-left: 10px;

          .btn {
            border-radius: 50%;
            margin: 0 3px;
            width: 35px;
            height: 35px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }

        .overlay-edit {
          position: absolute;
          opacity: 0;
          top: 0;
          right: 0;
          background: $primary-color;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          .btn {
            border-radius: 50%;
            margin: 0 3px;
            width: 35px;
            height: 35px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }
        }

        &:hover {
          box-shadow: 0 0 6px 0 $primary-color;

          td {
            cursor: pointer;
            color: #fff !important;
            background: $primary-color;

            h6 {
              color: #fff;
            }

            .state-txt {
              color: #fff !important;
            }

            .overlay-edit {
              opacity: 1;
            }

            .overlay-state {
              opacity: 0;
            }

            &:hover {
              .overlay-state {
                opacity: 1;
              }
            }
          }
        }

        td {
          .avatar-list-width {
            width: 40px;
          }
        }
      }
    }

    tr {
      td,
      th {
        vertical-align: middle;
        border: none;
      }

      td {
        background: #fff;
        position: relative;
        font-size: 13px;

        .state-txt {
          color: #fff !important;
        }

        &:first-child {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }

        &:last-child {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }
}

.dataTables_empty {
  display: none;
}
.no-data-available {
  text-align: center;
}

